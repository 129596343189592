import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Page from '@lib/components/v2/Page';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { useFlowTypeContext } from '@lib/components/v2/App/FlowTypeProvider';
import { Title } from '@lib/components/v2/Title';
import { isLocalizedStringDefined, localizedString } from '@languages';
import {
  CAPTURE_ADDITIONAL_DOCS_MAX_LIMIT,
  FORCE_CAPTURE_ADDITIONAL_DOCS
} from '@spotMobileConfig';
import Animation, { useAnimationUrls } from '@components/Animation';
import APIs from '@services/APIs';

import { Error500 } from '@FLOW_V2_FLOW/errors';
import LoadingBar from '@lib/components/v2/LoadingBar';
import { Radio } from '@lib/components/v2/Form';
import classes from './CaptureExtra.style.module.scss';

export const CaptureExtra = ({ onNextStep }) => {
  const hiddenInputElementRef = useRef();
  const { flowType } = useFlowTypeContext();
  const { animationUrls } = useAnimationUrls();
  const [progressBar, setProgressBar] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isCaptured, setIsCaptured] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [extraCaptureAttempt, setExtraCaptureAttempt] = useState(0);
  const [optionList, setOptionList] = useState(() => [
    {
      type: 'YES',
      title: localizedString('yes'),
      checked: true
    },
    {
      type: 'NO',
      title: localizedString('no'),
      checked: false
    }
  ]);

  let footerButtons = [];
  if (isUploading) {
    footerButtons = [
      {
        label: localizedString(
          isLocalizedStringDefined('app.captureExtra.button.back')
            ? 'app.captureExtra.button.back'
            : 'back'
        ),
        variant: 'transparent',
        onClick: () => {
          setIsUploading(false);
        }
      },
      {
        label: 'Uploading',
        variant: 'transparent',
        loading: true
      }
    ];
  } else if (isCaptured) {
    footerButtons = [
      {
        label: localizedString('app.captureExtra.button.finish'),
        type: 'submit',
        onClick: (e) => {
          e.currentTarget.disabled = true;
          onNextStep({ additionalInfo: true });
        },
        dataTestId: 'docCapt-finish'
      }
    ];
  } else {
    footerButtons = [
      {
        label: localizedString(
          `app.captureExtra.button.${optionList[0].checked ? 'captureDocuments' : 'finish'}`
        ),
        type: 'submit',
        onClick: handleNextStep,
        dataTestId: 'docs-capture-button'
      }
    ];
  }

  return (
    <div>
      <input
        type="file"
        name="image"
        accept="image/*"
        capture="environment"
        onChange={handleCapture}
        ref={hiddenInputElementRef}
        style={{ opacity: 0, zIindex: 99 }}
        aria-hidden="true"
        data-testid="extra-doc-capture-hidden-input"
      />
      {hasError && (
        <Error500
          buttons={[
            {
              label: localizedString('tryAgain'),
              full: true,
              onClick: () => {
                setHasError(false);
                setIsUploading(false);
                setProgressBar(0);
              }
            }
          ]}
        />
      )}
      {!hasError && (
        <Page buttons={footerButtons} forceFillViewPort={isUploading || isCaptured}>
          {!isUploading && !isCaptured && (
            <div className={classes.wrapper}>
              <Title title={localizedString('app.captureExtra.heading')} />
              <div className={classes.description} data-testid="description">
                {parse(localizedString('app.captureExtra.description'))}
              </div>
              <div className={classes.option_list}>
                {optionList.map((item, index) => (
                  <Radio
                    key={item.title}
                    id={`docs-${index}`}
                    onChange={() => onOptionClick(item, index)}
                    checked={item.checked}
                    title={item.title}
                    data-testid={`docs-${item.title.toLowerCase()}`}
                    hidden={FORCE_CAPTURE_ADDITIONAL_DOCS}
                  />
                ))}
              </div>
              {animationUrls.CAPTURE_EXTRA && (
                <div className={classes['animation-container']}>
                  <Animation animationUrl={animationUrls.CAPTURE_EXTRA} />
                </div>
              )}
            </div>
          )}

          {isUploading && (
            <div className={classNames(classes.wrapper, classes.fill_view_port)}>
              <Title title={localizedString('app.captureExtra.uploading.heading')} />
              <div className={classes.description}>
                {localizedString('app.captureExtra.uploading.description')}
              </div>
              <div className={classes['animation-container']}>
                <Animation animationUrl={animationUrls.REVIEW} />
              </div>
              <LoadingBar width={progressBar} />
            </div>
          )}

          {isCaptured && !isUploading && (
            <div className={classNames(classes.wrapper, classes.fill_view_port)}>
              <div className={classes.captured_content} data-testid="docCapt-txt">
                <img alt="" src="images/icons/svg/icon_document.svg" data-testid="docCapt-img" />
                {localizedString('app.captureExtra.captured.heading')}
              </div>
              {extraCaptureAttempt < CAPTURE_ADDITIONAL_DOCS_MAX_LIMIT && (
                <button
                  type="button"
                  className={classes.capture_another}
                  onClick={() => hiddenInputElementRef.current.click()}
                  data-testid="docCapt-anotherDoc"
                >
                  {localizedString('app.captureExtra.button.captureAnother')}
                </button>
              )}
            </div>
          )}
        </Page>
      )}
    </div>
  );

  function onOptionClick(item, index) {
    if (item.checked) {
      return;
    }
    setOptionList(optionList.map((option, i) => ({ ...option, checked: index === i })));
  }

  function handleNextStep(e) {
    e.currentTarget.disabled = true;
    if (e) {
      e.preventDefault();
    }

    const selectedItem = optionList.find((item) => item.checked);

    if (selectedItem.type === 'NO' && !FORCE_CAPTURE_ADDITIONAL_DOCS) {
      onNextStep();
    } else {
      hiddenInputElementRef.current.click();
    }
  }

  function handleCapture(e) {
    if (!e.target.files[0]) {
      return;
    }
    const imagefile = e.target.files[0];
    e.target.value = '';

    uploadImage(imagefile);
  }

  function uploadImage(imagefile) {
    APIs.status('additionalInfo');

    const params = { frontFile: imagefile, flowType, ocr: false, idType: 'Additional Documents' };

    setIsUploading(true);
    setProgressBar(0);

    APIs.uploadImage(
      params,
      {
        before: () => setProgressBar(0),
        onProgress: (width) => setProgressBar(width)
      },
      '/api/v4'
    )
      .then(({ status, msg: error }) => {
        if (status === 'error') {
          console.warn(error);
          throw error;
        }

        setIsUploading(false);
        setIsCaptured(true);
        setExtraCaptureAttempt(extraCaptureAttempt + 1);
      })
      .catch(() => {
        setHasError(true);
      });
  }
};

CaptureExtra.propTypes = {
  onNextStep: PropTypes.func.isRequired
};
